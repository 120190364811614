
import * as ipxRuntime$bkNR9QNyT3 from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxStaticRuntime$HfJ7CXLl8a from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "ds.monerix.com"
  ],
  "alias": {
    "/directus": "https://ds.monerix.com/assets"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$bkNR9QNyT3, defaults: {} },
  ['ipxStatic']: { provider: ipxStaticRuntime$HfJ7CXLl8a, defaults: {} }
}
        